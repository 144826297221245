import React from "react";
import { RichText } from "prismic-reactjs";
import { GatsbyImage } from "gatsby-plugin-image";

import { Col, Container, Row } from "react-bootstrap";

import AnchorPrismic from "../elements/AnchorPrismic";
import AppsIcons from "../elements/AppsIcons";

import "../../styles/components/slices/chargespot-apps.scss";

const ChargespotApps = ({ slice, settings }) => {
  const { title, image, text } = slice.primary;

  return (
    <section className="chargespot-apps py-5 py-md-7">
      <h2 className="h3 mb-5 mb-md-7 text-center">{title}</h2>
      <Container fluid>
        <Row className="align-items-lg-center">
          <Col
            xs={{ order: 1 }}
            md={{ span: 6, order: 0 }}
            xxl={{ span: 4, offset: 2 }}
          >
            <RichText
              render={text.richText}
              serializeHyperlink={AnchorPrismic}
            />
            <AppsIcons
              settings={settings}
              size="large"
              className="site-apps--horizontal"
            />
          </Col>
          <Col xxl={4} md={6}>
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.alt || title || ""}
              className="mb-4"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ChargespotApps;

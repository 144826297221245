import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

import { Col, Container, Row } from "react-bootstrap";

import Icon from "../elements/Icon";
import AnchorPrismic from "../elements/AnchorPrismic";
import CtaChargespot from "../common/CtaChargespot";

import "../../styles/components/slices/watt-volt-intro.scss";

const WattVoltIntro = ({ slice, settings }) => {
  const {
    come_chargespot_label: chargespotLabel,
    come_chargespot_link: chargespotLink,
    wv_logo: wvLogo,
  } = settings.data;

  const { background, image_mobile: imageMobile, text } = slice.primary;

  return (
    <section className="brand-intro position-relative mt-5 mt-md-0 overflow-hidden">
      <figure className="brand-intro__media">
        <GatsbyImage
          image={background.gatsbyImageData}
          alt={background.alt || ""}
          className={imageMobile.gatsbyImageData ? "d-none d-md-block" : null}
        />
        <GatsbyImage
          image={imageMobile.gatsbyImageData}
          alt={imageMobile.alt || ""}
          className="d-md-none"
        />
      </figure>
      <Container fluid>
        <Row>
          <Col xl={7} className="col-xxxl-4 offset-xxxl-2">
            <div className="brand-intro__content mt-xl-7 mb-6 mb-xxl-7 mb-xxxl-9">
              <Icon icon={wvLogo} />
              <div className="my-5 mt-md-6">
                <RichText
                  render={text.richText}
                  serializeHyperlink={AnchorPrismic}
                />
              </div>
              <CtaChargespot
                className="h5"
                data={{ chargespotLabel, chargespotLink }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WattVoltIntro;

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Col, Container, Row } from "react-bootstrap";

import Anchor from "../elements/Anchor";

const ColumnBanners = ({ slice }) => {
  if (slice.items.length < 1) return null;

  const banners = slice.items.map((banner) => (
    <Col md={6} className="mb-5" key={banner.image.url + banner.header}>
      <GatsbyImage
        image={banner.image.gatsbyImageData}
        alt={banner.image.alt || banner.header || ""}
      />
      <div className="d-grid justify-content-center">
        {banner.header && <h2 className="h3">{banner.header}</h2>}
        {banner.link.url && banner.link_label && (
          <Anchor
            href={banner.link.url}
            target={banner.link.target}
            className="link-cta"
          >
            {banner.link_label}
          </Anchor>
        )}
      </div>
    </Col>
  ));
  return (
    <section className="column-banners">
      <Container fluid>
        <Row>{banners}</Row>
      </Container>
    </section>
  );
};

export default ColumnBanners;

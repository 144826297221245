import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

import { Col, Container, Row } from "react-bootstrap";

import Anchor from "../elements/Anchor";
import AnchorPrismic from "../elements/AnchorPrismic";

import "../../styles/components/slices/image-with-text.scss";

const ImageWithText = ({ slice }) => {
  const {
    header,
    image,
    image_mobile: imageMobile,
    image_left: imageLeft,
    link,
    link_label: linkLabel,
    text,
    title,
  } = slice.primary;

  return (
    <section className="image-text mb-5 mb-md-7" data-image-left={imageLeft}>
      <Container className="mw-1920 mx-auto p-0" fluid>
        {title && (
          <h2
            className="my-5 mt-md-7 mb-md-8 text-center"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <Row className="g-0 align-items-center">
          <Col xxl={{ span: 8, order: imageLeft ? 0 : 1 }}>
            {image.gatsbyImageData && (
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.alt || header || ""}
                className={
                  imageMobile.gatsbyImageData ? "d-none d-md-block" : null
                }
              />
            )}
            {imageMobile.gatsbyImageData && (
              <GatsbyImage
                image={imageMobile.gatsbyImageData}
                alt={imageMobile.alt || header || ""}
                className="d-md-none"
              />
            )}
          </Col>
          <Col xxl={{ span: 3, offset: imageLeft ? 0 : 1 }}>
            <div
              // eslint-disable-next-line prettier/prettier
              className={`image-text__content vertical-line-point${imageLeft ? " vertical-line-point--right text-md-end" : ""}`}
            >
              {header && <h3 className="break-words h2 mb-5">{header}</h3>}
              {text.text !== "" && (
                // eslint-disable-next-line prettier/prettier
                <div className={`image-text__text mb-5 ${imageLeft ? "ms-md-auto" : ""}`}>
                  <RichText
                    render={text.richText}
                    serializeHyperlink={AnchorPrismic}
                  />
                </div>
              )}
              {link.url && linkLabel && (
                <Anchor
                  href={link.url}
                  target={link.target}
                  className="link-cta"
                >
                  {linkLabel}
                </Anchor>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ImageWithText;

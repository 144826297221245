import React from "react";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, A11y, Navigation } from "swiper";
import { GatsbyImage } from "gatsby-plugin-image";

import { Container } from "react-bootstrap";

import { ArrowLeft, ArrowRight } from "../../helpers/Icons";

/* eslint-disable */
import "swiper/scss";
import "swiper/scss/autoplay";
import "swiper/scss/a11y";
import "swiper/scss/navigation";
/* eslint-enable */

import "../../styles/components/slices/logo-slider.scss";

const LogoSlider = ({ slice }) => {
  if (slice.items?.length < 1) return null;

  const logos = slice.items.map((logo) => (
    <SwiperSlide key={logo.image.url} className="w-auto">
      <GatsbyImage
        image={logo.image.gatsbyImageData}
        alt={logo.image.alt || ""}
        className="bg-light"
      />
    </SwiperSlide>
  ));

  return (
    <section className="logo-slider mb-5">
      {slice.primary.title && (
        <Container as="header" fluid>
          <h2
            className="my-5 mt-md-7 mb-md-8 text-center"
            dangerouslySetInnerHTML={{ __html: slice.primary.title }}
          />
        </Container>
      )}
      <Swiper
        modules={[Autoplay, A11y, Navigation]}
        slidesPerView="auto"
        centeredSlides
        initialSlide={5}
        spaceBetween={20}
        autoplay={{ delay: 700 }}
        speed={800}
        loop
        loopedSlides={1}
        grabCursor
        navigation={{
          prevEl: ".logo-slider__nav-prev",
          nextEl: ".logo-slider__nav-next",
        }}
      >
        {logos}
      </Swiper>
      <div className="d-flex justify-content-center mt-4">
        <button type="button" className="btn logo-slider__nav-prev me-2">
          <ArrowRight />
          <span className="visually-hidden">Previous slide</span>
        </button>
        <button type="button" className="btn logo-slider__nav-next">
          <ArrowLeft />
          <span className="visually-hidden">Next slide</span>
        </button>
      </div>
    </section>
  );
};

export default LogoSlider;

import React from "react";
import HeroSlider from "../slices/HeroSlider";
import ChargespotApps from "../slices/ChargespotApps";
import WattVoltIntro from "../slices/WattVoltIntro";
import ImageWithText from "../slices/ImageWithText";
import ColumnBanners from "../slices/ColumnBanners";
import LogoSlider from "../slices/LogoSlider";
import BannersWithContent from "../slices/BannersWithContent";
import HeaderWithBackground from "../slices/HeaderWithBackground";
import IconsWithtext from "../slices/IconsWithtext";
import Accordion from "../slices/Accordion";
import Image from "../slices/Image";
import ContentWithAccordion from "../slices/ContentWithAccordion";
import TextWithLogo from "../slices/TextWithLogo";
import Counters from "../slices/Counters";

const SliceZone = ({ slices, settings }) => {
  const sliceComponents = {
    hero_slider: HeroSlider,
    chargespot_apps: ChargespotApps,
    watt_volt_intro: WattVoltIntro,
    image_with_text: ImageWithText,
    column_banners: ColumnBanners,
    logo_slider: LogoSlider,
    banners_with_content: BannersWithContent,
    header_with_background: HeaderWithBackground,
    icons_with_text: IconsWithtext,
    accordion: Accordion,
    image: Image,
    content_with_accordion: ContentWithAccordion,
    text_with_logo: TextWithLogo,
    counters: Counters,
  };

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    const key = slice.id + index;

    if (SliceComponent) {
      return <SliceComponent slice={slice} settings={settings} key={key} />;
    }

    return null;
  });
};

export default SliceZone;

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

import { Container } from "react-bootstrap";

import Anchor from "../elements/Anchor";
import AnchorPrismic from "../elements/AnchorPrismic";

const TextWithLogo = ({ slice }) => {
  if (!slice) return null;
  const { content, logo, logo_link: logoLink } = slice.primary;

  return (
    <Container
      as="section"
      className="text-with-logo mw-1000 mb-5 mb-md-7 px-md-2 text-center"
    >
      <RichText render={content.richText} serializeHyperlink={AnchorPrismic} />
      <Anchor href={logoLink.url} target={logoLink.target} title={logo.alt}>
        <GatsbyImage
          image={logo.gatsbyImageData}
          alt={logo.alt}
          className="rounded"
        />
      </Anchor>
    </Container>
  );
};

export default TextWithLogo;

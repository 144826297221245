import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Col, Container, Row } from "react-bootstrap";

import AccordionSimple from "../elements/AccordionSimple";
import CtaChargespot from "../common/CtaChargespot";
import Anchor from "../elements/Anchor";

const ContentWithAccordion = ({ slice, settings }) => {
  if (!slice) return null;

  const {
    ca_chargespot: chargespot,
    ca_content: content,
    ca_image: image,
    ca_left: left,
    ca_title: title,
    title_link: titleLink,
  } = slice.primary;

  const {
    come_chargespot_label: chargespotLabel,
    come_chargespot_link: chargespotLink,
  } = settings.data;

  return (
    <section className="content-accordion mt-5 mb-8">
      <Container fluid>
        <Row className="mb-3 mb-md-5">
          <Col
            md={{ span: 10, offset: 1 }}
            lg={{ span: 4, offset: left ? 7 : 1, order: left ? 1 : 0 }}
          >
            {title && (
              <Anchor href={titleLink.url} className="link-unstyled">
                <h2
                  className="h3"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </Anchor>
            )}
            {content && <p>{content}</p>}
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4, offset: left ? 0 : 1, order: left ? 1 : 0 }}>
            {image.gatsbyImageData && (
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.alt || title || ""}
              />
            )}
          </Col>
          <Col md={{ span: 6, offset: left ? 1 : 0 }}>
            <AccordionSimple items={slice.items} />
            {chargespot && (
              <CtaChargespot
                data={{ chargespotLabel, chargespotLink }}
                className="h5 mt-4"
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContentWithAccordion;

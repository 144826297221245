import React from "react";
import { Container } from "react-bootstrap";

import "../../styles/components/slices/icons-with-text.scss";
import Icon from "../elements/Icon";

const IconsWithtext = ({ slice }) => {
  const items = slice.items.map((item) => (
    <div className="icons-text__item" key={item.text}>
      <div className="icons-text__icon">
        <Icon icon={item.icon} />
      </div>
      <p className="icons-text__text mb-0">{item.text}</p>
    </div>
  ));

  return (
    <Container
      as="section"
      className="icons-text mw-1920 mb-4 mb-md-6 py-5"
      fluid
    >
      {items}
    </Container>
  );
};

export default IconsWithtext;

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import "../../styles/components/slices/header-with-background.scss";
import { Container } from "react-bootstrap";

const HeaderWithBackground = ({ slice }) => {
  const { background, image_mobile: imageMobile, header } = slice.primary;

  return (
    <section className="header-with-background">
      {background.gatsbyImageData && (
        <GatsbyImage
          image={background.gatsbyImageData}
          alt={background.alt || ""}
          className={imageMobile.gatsbyImageData ? "d-none d-lg-block" : null}
        />
      )}
      {imageMobile.gatsbyImageData && (
        <GatsbyImage
          image={imageMobile.gatsbyImageData}
          alt={imageMobile.alt || ""}
          className="d-lg-none"
        />
      )}
      {header && (
        <Container fluid>
          <h2 dangerouslySetInnerHTML={{ __html: header }} />
        </Container>
      )}
    </section>
  );
};

export default HeaderWithBackground;

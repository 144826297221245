import React, { useContext } from "react";
import { RichText } from "prismic-reactjs";

import { Accordion, Col, Container, Row } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

import AnchorPrismic from "./AnchorPrismic";

import "../../styles/components/elements/accordion-horizontal.scss";

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <h2 className="mb-0">
      <button
        type="button"
        // eslint-disable-next-line prettier/prettier
        className={`accordion-horizontal__header pe-md-6 ${isCurrentEventKey ? " active" : ""}`}
        onClick={decoratedOnClick}
        onKeyPress={(e) => {
          if (e.key === "enter") decoratedOnClick();
        }}
      >
        {children}
      </button>
    </h2>
  );
};

const AccordionHorizontal = ({ items }) => {
  if (!items && items.length < 1) return null;

  const headers = items.map((item, index) => (
    <ContextAwareToggle eventKey={`acc${index}`} key={item.acc_header}>
      {item.acc_header}
    </ContextAwareToggle>
  ));

  const contents = items.map((item, index) => (
    <Accordion.Collapse eventKey={`acc${index}`} key={item.acc_content.text}>
      <RichText
        render={item.acc_content.richText}
        serializeHyperlink={AnchorPrismic}
      />
    </Accordion.Collapse>
  ));

  return (
    <Container fluid>
      <Accordion as={Row} defaultActiveKey="acc0">
        <Col
          md={6}
          xxl={{ span: 4, offset: 2 }}
          className="accordion-horizontal__headers-wrapper pe-0 py-md-6"
        >
          <div className="accordion-horizontal__headers">{headers}</div>
        </Col>
        <Col
          md={6}
          xxl={4}
          className="accordion-horizontal__contents py-md-6 px-md-5"
        >
          {contents}
        </Col>
      </Accordion>
    </Container>
  );
};

export default AccordionHorizontal;

import React from "react";

import AccordionHorizontal from "../elements/AccordionHorizontal";
import AccordionSimple from "../elements/AccordionSimple";
import CtaChargespot from "../common/CtaChargespot";

const Accordion = ({ slice, settings }) => {
  const { items, primary } = slice;

  const {
    acc_chargespot: chargespot,
    acc_horizontal: horizontal,
    acc_title: title,
  } = primary;

  const {
    come_chargespot_label: chargespotLabel,
    come_chargespot_link: chargespotLink,
  } = settings.data;

  return (
    <section
      className={`accordion mt-6${horizontal ? " accordion--horizontal" : ""}`}
    >
      {title && (
        <h2
          className="h5 mb-4 fw-bold"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      <div className={title ? "ps-md-5" : null}>
        {horizontal ? (
          <AccordionHorizontal items={items} />
        ) : (
          <AccordionSimple items={items} />
        )}
      </div>
      {chargespot && (
        <div className="my-6 px-3 text-center">
          <CtaChargespot
            data={{ chargespotLabel, chargespotLink }}
            className="h5"
          />
        </div>
      )}
    </section>
  );
};

export default Accordion;

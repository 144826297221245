import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

import { Container } from "react-bootstrap";

import Icon from "../elements/Icon";

import "../../styles/components/slices/counters.scss";

const Counters = ({ slice }) => {
  if (!slice) return null;

  const { counter_bg: background, counter_bg_mobile: backgroundMobile } =
    slice.primary;

  const items = slice.items.map((item) => {
    const { ref, inView } = useInView();
    const {
      counter_item_icon: icon,
      counter_item_number: number,
      counter_item_suffix: suffix,
      counter_item_text: text,
    } = item;

    return (
      <li className="counters__item mw-400" ref={ref} key={text}>
        <Icon icon={icon} />
        <div>
          {inView ? (
            <CountUp
              className="display-5 text-primary fw-bold"
              end={number}
              suffix={suffix || ""}
              separator="."
            />
          ) : (
            <span className="display-5 text-primary fw-bold">{number}</span>
          )}
          <p className="mb-0">{text}</p>
        </div>
      </li>
    );
  });

  return (
    <section className="counters section-background horizontal-shadow horizontal-shadow--bottom mt-6 pt-7 pt-md-10 pb-8 pb-md-7">
      <figure className="section-background__figure">
        <GatsbyImage
          image={background.gatsbyImageData}
          alt={background.alt || ""}
          className={
            backgroundMobile.gatsbyImageData ? "d-none d-md-block" : null
          }
        />
        {backgroundMobile.gatsbyImageData && (
          <GatsbyImage
            image={backgroundMobile.gatsbyImageData}
            alt={backgroundMobile.alt || ""}
            className="d-md-none"
          />
        )}
      </figure>
      <Container>
        <div className="d-inline-block p-5 py-md-5 px-md-6 bg-dark">
          <ul className="counters__list list-unstyled">{items}</ul>
        </div>
      </Container>
    </section>
  );
};

export default Counters;

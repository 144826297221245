import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

import { Col, Container, Row } from "react-bootstrap";

import Anchor from "../elements/Anchor";
import AnchorPrismic from "../elements/AnchorPrismic";

const BannersWithContent = ({ slice }) => {
  const banners = slice.items.map((banner) => (
    <Col lg={4} className="flex-fill mb-5" key={banner.header}>
      <div className="d-flex flex-column h-100 px-0 p-lg-5 p-xl-4 p-xxxl-6">
        {banner.image1.gatsbyImageData && (
          <GatsbyImage
            image={banner.image1.gatsbyImageData}
            alt={banner.image1.alt || banner.header || ""}
            className="mb-5 bg-light"
          />
        )}
        <div className="banners-content__content vertical-line-point flex-fill d-grid align-content-between">
          {banner.header && (
            <h2 className="break-words mb-5">{banner.header}</h2>
          )}
          {banner.content?.text !== "" && (
            <div className="mb-5">
              <RichText
                render={banner.content?.richText}
                serializeHyperlink={AnchorPrismic}
              />
            </div>
          )}
          {banner.link?.url && banner.link_label && (
            <Anchor
              href={banner.link?.url}
              target={banner.link?.target}
              className="link-cta"
            >
              {banner.link_label}
            </Anchor>
          )}
        </div>
      </div>
    </Col>
  ));

  return (
    <section className="banners-content pt-6 pb-4 py-lg-7">
      <Container className="p-0" fluid>
        <Row className="g-0">{banners}</Row>
      </Container>
    </section>
  );
};

export default BannersWithContent;

import React from "react";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, A11y, EffectFade, Pagination, Parallax } from "swiper";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import YouTube from "react-youtube";

import { Container } from "react-bootstrap";

import Anchor from "../elements/Anchor";
import AnchorPrismic from "../elements/AnchorPrismic";
import CtaChargespot from "../common/CtaChargespot";
import ScrollToTarget from "../elements/ScrollToTarget";

/* eslint-disable */
import "swiper/scss";
import "swiper/scss/autoplay";
import "swiper/scss/a11y";
import "swiper/scss/effect-fade";
import "swiper/scss/pagination";
import "swiper/scss/parallax";
/* eslint-enable */

import "../../styles/components/slices/hero-slider.scss";

const HeroSlider = ({ slice, settings }) => {
  const {
    come_chargespot_label: chargespotLabel,
    come_chargespot_link: chargespotLink,
  } = settings.data;

  const slides = slice.items.map((slide) => {
    const {
      show_chargespot: showChargespot,
      color,
      horizontal,
      vertical,
      image,
      image_mobile: imageMobile,
      link,
      link_label: linkLabel,
      text,
      title,
      video,
    } = slide;

    const hasYtVideo =
      video && (video.includes("youtube") || video.includes("youtu.be"));
    const videoId = hasYtVideo
      ? new URLSearchParams(new URL(video).search).get("v")
      : null;

    const slideMedia = (image.gatsbyImageData || hasYtVideo) && (
      <figure
        className="hero-slider__media position-absolute"
        data-swiper-parallax-scale={1.3}
        data-swiper-parallax-opacity={0.3}
        data-swiper-parallax-duration={1400}
      >
        {image.gatsbyImageData && (
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.alt || title || ""}
            className={
              imageMobile.gatsbyImageData
                ? "hero-slider__image d-none d-md-block"
                : null
            }
          />
        )}
        {imageMobile.gatsbyImageData && (
          <GatsbyImage
            image={imageMobile.gatsbyImageData}
            alt={imageMobile.alt || title || ""}
            className="hero-slider__image d-md-none"
          />
        )}
        {hasYtVideo && (
          <YouTube
            videoId={videoId}
            containerClassName="ratio ratio-16x9"
            className="pe-none"
            opts={{
              height: "100%",
              width: "100%",
              playerVars: {
                autoplay: 1,
                controls: 0,
                disablekb: 1,
                loop: 1,
                playlist: videoId,
                fs: 0,
                modestbranding: 1,
              },
            }}
            onReady={(e) => e.target.mute()}
          />
        )}
      </figure>
    );

    const slideContent = (
      <Container className="px-3 px-md-4" fluid>
        <div
          className={`hero-slider__content align-items-md-${horizontal} justify-content-md-${vertical} text-${color}`}
        >
          {title && (
            <h2
              className={`h1 fw-normal mb-3 text-md-${horizontal}`}
              data-swiper-parallax-x={-150}
              data-swiper-parallax-opacity={0}
              data-swiper-parallax-scale={0.85}
              data-swiper-parallax-duration={1600}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {text.text !== "" && (
            <div
              className={`hero-slider__text h3 mb-3 text-md-${horizontal}`}
              data-swiper-parallax-x={-200}
              data-swiper-parallax-opacity={0}
              data-swiper-parallax-scale={0.85}
              data-swiper-parallax-duration={1800}
            >
              <RichText
                render={text.richText}
                serializeHyperlink={AnchorPrismic}
              />
            </div>
          )}
          <span
            data-swiper-parallax-x={-200}
            data-swiper-parallax-opacity={0}
            data-swiper-parallax-scale={0.85}
            data-swiper-parallax-duration={2000}
          >
            {link.url && linkLabel && (
              <Anchor
                href={link.url}
                target={link.target}
                className="h5 fw-bold mb-4"
              >
                {linkLabel}
              </Anchor>
            )}
            {showChargespot && (
              <CtaChargespot
                className="h5"
                data={{ chargespotLabel, chargespotLink }}
              />
            )}
          </span>
        </div>
      </Container>
    );

    return (
      <SwiperSlide key={title + image.url}>
        <div className="hero-slider__slide position-relative overflow-hidden">
          {slideMedia}
          {slideContent}
        </div>
      </SwiperSlide>
    );
  });

  return (
    <section className="hero-slider">
      <Swiper
        modules={[Autoplay, A11y, EffectFade, Pagination, Parallax]}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        autoplay={{ delay: 5000 }}
        pagination={{ clickable: true }}
        speed={600}
        grabCursor
        parallax
        loop
        className="horizontal-shadow horizontal-shadow--bottom"
      >
        {slides}
      </Swiper>
      <ScrollToTarget />
    </section>
  );
};

export default HeroSlider;

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Image = ({ slice }) => {
  if (!slice && slice.primary.im_image === null) return null;

  const {
    im_image: image,
    im_image_mobile: imageMobile,
    im_fullwidth: fullwidth,
  } = slice.primary;

  return (
    <figure className={`image${!fullwidth ? " container" : " w-100"}`}>
      <GatsbyImage
        image={image.gatsbyImageData}
        alt={image.alt || ""}
        className={imageMobile.gatsbyImageData ? "d-none d-md-block" : null}
      />
      {imageMobile.gatsbyImageData && (
        <GatsbyImage
          image={imageMobile.gatsbyImageData}
          alt={imageMobile.alt || ""}
          className="d-md-none"
        />
      )}
    </figure>
  );
};

export default Image;

import React, { useEffect, useState } from "react";
import { RichText } from "prismic-reactjs";

import { Accordion } from "react-bootstrap";

import AnchorPrismic from "./AnchorPrismic";

import "../../styles/components/elements/accordion-simple.scss";

const AccordionSimple = ({ items }) => {
  const [activeKey, setActiveKey] = useState(items[0].acc_anchor || "as0");

  useEffect(() => {
    if (window.location.hash === "") return null;

    const scrollToAnchor = () => {
      const locationHash = window.location.hash.replace("#", "");

      if (!items.some((item) => item.acc_anchor === locationHash)) {
        setActiveKey(null);
        return null;
      }
      const el = document.querySelector(`[data-id="${locationHash}"]`);

      if (!el) return null;
      setActiveKey(locationHash);
      setTimeout(() => {
        window.scrollTo({
          top: el.offsetTop - 150,
          left: 0,
          behavior: "smooth",
        });
      }, 400);

      return null;
    };

    scrollToAnchor();
    window.onhashchange = scrollToAnchor;

    return null;
  }, [items]);

  const handleHeaderClick = (id) => {
    if (id === activeKey) {
      setActiveKey(null);
    } else {
      setActiveKey(id);
    }
  };

  const content = items.map((item, index) => (
    <Accordion.Item
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(item.acc_anchor ? { "data-id": item.acc_anchor } : null)}
      eventKey={item.acc_anchor || `as${index}`}
      key={item.acc_header}
    >
      <Accordion.Header
        as="h3"
        className="h5"
        onClick={() => handleHeaderClick(item.acc_anchor || `as${index}`)}
      >
        {item.acc_header}
      </Accordion.Header>
      <Accordion.Body>
        <RichText
          render={item.acc_content.richText}
          serializeHyperlink={AnchorPrismic}
        />
      </Accordion.Body>
    </Accordion.Item>
  ));

  return (
    <Accordion className="accordion-simple" activeKey={activeKey}>
      {content}
    </Accordion>
  );
};

export default AccordionSimple;
